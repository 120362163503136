export type BackgroundColors = 'grey' | 'white';

export type Chapter = {
  id: Chapters;
  titleKey: string;
  productTypes: LicensedProduct[];
  mandatory: boolean;
};

export type ChapterPositions = Record<string, number>;

export type Chapters =
  | 'FOUNDATION'
  | 'MANAGEMENT'
  | 'EFFECTIVE_SELLING'
  | 'PERSONAL_ACHIEVEMENT'
  | 'INTERVIEW'
  | 'IDTL'
  | 'DFC';

export type ColourEnergy = Record<JungianColours, string>;

export type DatadogRumConfig = {
  hostname: string;
  applicationId: string;
  clientToken: string;
};

export type DotType = '4-COLOUR' | '8-COLOUR';

export type EventType =
  | 'INSIGHTS_DISCOVERY_WORKSHOP'
  | 'INSIGHTS_DISCOVERY_ACCREDITATION'
  | 'INSIGHTS_SELF_AWARE_LEADER'
  | 'INSIGHTS_DISCOVERING_LEADERSHIP_EFFECTIVENESS'
  | 'INSIGHTS_DISCOVERY_FULL_CIRCLE'
  | 'INSIGHTS_EXPLORE';

export type EventTypes = Record<
  EventType,
  {
    textKey: string;
    color: InsightsColors;
  }
>;

export type Frequency = 'DAILY' | 'WEEKLY' | 'BIWEEKLY' | 'NEVER';

export type FrequencyType = {
  textKey: string;
  value: Frequency;
};

export type InsightsColors =
  | 'blue'
  | 'orange'
  | 'green'
  | 'darkGreen'
  | 'darkOrange'
  | 'pink'
  | 'red'
  | 'grey';

export type InviteeListOptions =
  | 'ADDED'
  | 'INVITE_SENT'
  | 'INVITE_REQUESTED'
  | 'READY_TO_PURCHASE'
  | 'PROFILE_GENERATED';

export type InviteeOptions = Record<
  Exclude<InviteeListOptions, 'INVITE_REQUESTED'>,
  {
    textKey: string;
    color: InsightsColors;
  }
>;
export type InviteeStatuses = Record<
  InviteeListOptions,
  {
    textKey: string;
    color: InsightsColors;
  }
>;

export type SimplifiedJungianColours = 'blue' | 'green' | 'yellow' | 'red';

export type InviteeColorScores = {
  [colour in SimplifiedJungianColours]: number;
} & {
  preferred: SimplifiedJungianColours[];
};

export type JobTitle =
  | 'CLIENT_PRACTITIONER'
  | 'LICENSED_PRACTITIONER'
  | 'ADMIN'
  | 'INSIGHTS_ADMIN';

export type JobTitles = Record<JobTitle, string>;

export type JungianColours =
  | 'CoolBlue'
  | 'EarthGreen'
  | 'SunshineYellow'
  | 'FieryRed';

export const LICENSED_PRODUCTS_ARRAY = [
  'DISCOVERY_SELF_AWARE_PROFILE',
  'DISCOVERY_PERSONAL_PROFILE',
  'DISCOVERY_TRANSFORMATIONAL_LEADERSHIP_PROFILE',
  'DISCOVERY_FULL_CIRCLE_PROFILE',
  'DISCOVERY_EXPLORE_PROFILE',
] as const;

export type LicensedProductOption = Record<
  LicensedProduct,
  Option<LicensedProduct>
>;

export type Option<T extends string> = {
  value: T;
  label: string;
  disabled?: boolean;
  defaultSelected?: boolean;
};

export type Options = Option<string>[];

export type LicensedProduct = (typeof LICENSED_PRODUCTS_ARRAY)[number];

export type LicensedProducts = Record<LicensedProduct, string>;

export type ProductTypes = Record<LicensedProduct, string>;

export type ProfileType =
  | 'DISCOVERY_PERSONAL_PROFILE'
  | 'DISCOVERY_PROFILE'
  | 'IDTL_PROFILE'
  | 'DFC_PROFILE'
  | 'EXPLORE_PROFILE'
  | 'API_PROFILE';

export type ProfileTypes = Record<ProfileType, string>;

export type QueryKeyRoot =
  | 'EVENT_DATA'
  | 'EVENTS_DATA'
  | 'INVITEES_DATA'
  | 'EVENT_TEAM_SCORES_DATA'
  | 'EVENT_REMINDER_DATA'
  | 'PRACTITIONER_DATA'
  | 'PRACTITIONERS_DATA'
  | 'PRACTITIONER_CANCELLED_EVENTS'
  | 'PRACTITIONER_ORGANISATION_GROUP_DATA'
  | 'OPEN_PRACTITIONER_INVITES_DATA'
  | 'PRACTITIONERS_SUMMARY_DATA'
  | 'MY_TRANSACTIONS_DATA'
  | 'MY_LATEST_TRANSACTIONS_DATA'
  | 'PRACTITIONER_PRIMARY_WALLET_DATA'
  | 'TEAM_DATA'
  | 'TEAM_LEARNERS_DATA'
  | 'TEAMS_DATA'
  | 'MY_TEAMS_DATA'
  | 'TEAM_SCORES_DATA'
  | 'TEAM_WHEEL_SUMMARY'
  | 'TEAM_COLOUR_COUNTS'
  | 'ORGANISATIONS_DATA'
  | 'ORGANISATION_DATA'
  | 'ORGANISATION_EVENT_DATA'
  | 'ORGANISATION_EVALUATOR_LINKS'
  | 'ORGANISATION_EVALUATOR_LINK_EVALUATIONS'
  | 'MY_ORGANISATION_DATA'
  | 'LEARNER_DATA'
  | 'LEARNERS_DATA'
  | 'LEARNER_TEAM_DATA'
  | 'PROFILES_DATA'
  | 'ARCHIVED_PROFILES_DATA'
  | 'EVALUATOR_PROFILES'
  | 'ORGANISATION_GROUP_DATA'
  | 'ORGANISATION_GROUPS_DATA'
  | 'ORGANISATIONS_ORGANISATION_GROUP_DATA'
  | 'CONTRIBUTORS_DATA'
  | 'ARCHIVED_PROFILES_DATA'
  | 'SUPPORTED_ROLES_DATA';

export type QueryKeyRoots = Record<QueryKeyRoot, string>;

export type Role =
  | 'SUPER_ADMIN'
  | 'GROUP_MANAGER'
  | 'ADVANCED_USER'
  | 'STANDARD';

export type Roles = Record<Role, string>;

export type RolePositions = Record<string, number>;

export type ScoresGroupingType = 'AGGREGATED' | 'DISTINCT';

export type ScoreType = 'CONSCIOUS' | 'LESS_CONSCIOUS';

export enum SortDirection {
  ASCENDING = 'ASCENDING',
  DESCENDING = 'DESCENDING',
}

export enum SortType {
  NUMBER = 'NUMBER',
  NAME = 'NAME',
  WHEEL_POSITION = 'WHEEL_POSITION',
  COLOUR_PREF = 'COLOUR_PREF',
  ORGANISATION = 'ORGANISATION',
}

export type User = {
  fullName: string;
  emailAddress: string;
  id: string;
};

export type Evaluator = 'DISCOVERY' | 'DDDRS' | 'DFC' | 'EXPLORE';

type Pronoun = 'HE' | 'SHE' | 'THEY';

export interface HasStatus {
  status: InviteeListOptions;
}

export interface Contributor extends HasStatus {
  id: string;
  email: string;
  fullName: string;
  status: InviteeListOptions;
  pronoun?: Pronoun;
  discoveryProfile?: null | {
    createdAt: string;
  };
  evaluation?: { submittedOn: string | undefined };
}

export type EvaluatorLinksSortCategory =
  | 'DATE_CREATED'
  | 'MOST_COMPLETED'
  | 'LEAST_COMPLETED';

export enum InviteUserOrgOptions {
  ONE_ORG = 'ONE_ORG',
  MULTI_ORG = 'MULTI_ORG',
}

export type AnonymisationType = 'NAMED' | 'ANONYMISED';

type NthTupleOfLength<
  N extends number,
  T,
  R extends unknown[],
> = R['length'] extends N ? R : NthTupleOfLength<N, T, [T, ...R]>;

export type TupleOfLength<N extends number, T> = N extends N
  ? number extends N
    ? T[]
    : NthTupleOfLength<N, T, []>
  : never;
