import React from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { Helmet } from 'react-helmet';
import {
  H1,
  P,
  Span,
  Text,
  InputText,
  InputType,
} from '@insights-ltd/design-library/components';
import SelfServiceLayout from 'components/layout/SelfServiceLayout';
import { useInitiateSignIn } from 'api/practitioners/practitionerHooks';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Box from '@mui/material/Box/Box';
import LanguageSelecetWrapper from 'components/LanguageSelectWrapper/LanguageSelectWrapper';

type SignInFormData = {
  emailAddress: string;
};

const EmailRequiredLabel = () => (
  <Trans
    i18nKey="ui.event-management.sign-in.email.input-label-text"
    components={{
      bold: <Text color="textPrimary" variant="body-bold" />,
      styles: <Text color="error" />,
    }}
  />
);

const SignIn = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { mutateAsync: initiateSignIn, isPending } = useInitiateSignIn();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SignInFormData>();

  async function onSubmit(credentials: SignInFormData) {
    const response = await initiateSignIn(credentials);
    const paramsString = searchParams.size
      ? `?${decodeURIComponent(searchParams.toString())}`
      : '';
    if (response.method === 'PASSWORD') {
      navigate(`/signin/password${paramsString}`, {
        state: { email: credentials.emailAddress },
      });
    } else {
      window.location.href = response.url;
    }
  }

  return (
    <>
      <Helmet>
        <title>{t('ui.event-management.title.sign-in')}</title>
      </Helmet>
      <SelfServiceLayout>
        <Paper
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(spacingSizeMap.S),
            width: '100%',
            maxWidth: '31rem',
            marginBottom: theme.spacing(spacingSizeMap.L),
            padding: theme.spacing(spacingSizeMap.L),
          })}
        >
          <Box alignSelf="self-end" width="auto">
            <LanguageSelecetWrapper />
          </Box>
          <H1 variant="h3">{t('ui.event-management.sign-in.heading')}</H1>
          <P variant="body" color="textSecondary">
            <Trans
              i18nKey="ui.event-management.sign-in.sub-heading.bold"
              components={{ bold: <Span variant="body-bold" /> }}
            />
          </P>
          <InputText
            id="emailAddress"
            type={InputType.EMAIL}
            label={<EmailRequiredLabel />}
            placeholder={t(
              'ui.event-management.users.create.email.placeholder',
            )}
            error={Boolean(errors.emailAddress)}
            fullWidth
            helperText={
              errors.emailAddress &&
              t('ui.event-management.sign-in.email.error.required-polite')
            }
            aria-labelledby={undefined}
            aria-describedby={undefined}
            autoFocus
            {...register('emailAddress', { required: true })}
          />

          <Button
            variant="contained"
            color="primary"
            disabled={isPending}
            type="submit"
            size="large"
            sx={{ alignSelf: 'flex-end' }}
          >
            {t('ui.event-management.sign-in.sso.heading')}
          </Button>
        </Paper>
      </SelfServiceLayout>
    </>
  );
};

export default SignIn;
